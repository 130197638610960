/* blue */
/* var(--color-blue-500): #0065bd */
/* var(--color-blue-600): #004c9a */

/* gray */
/* var(--color-gray-50): #f7f8f9 */
/* var(--color-gray-100): #e0e4e7 */
/* var(--color-gray-800): #293036 */

.primary-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.primary-item-menu {
    padding: 8px 12px;
}

.primary-item:active,
.primary-item.active {
    color: #004c9a; /* var(--color-blue-600) */
    background-color: #e0e4e7; /* var(--color-gray-100) */
}

.primary-item:hover {
    color: #0065bd; /* var(--color-blue-500) */
    background-color: #f7f8f9; /* var(--color-gray-50) */
}

.primary-item:hover a {
    color: #0065bd; /* var(--color-blue-500) */
}

.secondary-item {
    cursor: pointer;
    margin-left: 8px;
    color: #293036; /* var(--color-gray-800) */
}

.secondary-item:active,
.secondary-item.active {
    background-color: #e0e4e7; /* var(--color-gray-100) */
}

.secondary-item:hover {
    background-color: #f7f8f9; /* var(--color-gray-50 */
}

.mobile-link {
    flex: 1;
    padding: 8px 12px;
    display: block;
    text-decoration: none;
    color: #293036; /* var(--color-gray-800) */
}

.mobile-link:active,
.mobile-link.active {
    color: #004c9a; /* var(--color-blue-600) */
}

.mobile-link:hover {
    color: #0065bd; /* color: var(--color-blue-500) */
}
/* blue */
/* var(--color-blue-500): #0065bd */
/* var(--color-blue-600): #004c9a */

/* gray */
/* var(--color-gray-50): #f7f8f9 */
/* var(--color-gray-100): #e0e4e7 */
/* var(--color-gray-200): #c1c9d0 */
/* var(--color-gray-300): #a2adb8 */
/* var(--color-gray-800): #293036 */

/* overall top-nav area */
.top-nav {
    position: sticky;
	top: 0;
	z-index: 199999; /* higher than anchor nav, look into that number */
    background-color: white;
}

/* area where actual content is restricted to */
.top-nav-content {
    margin: 0 auto;
    padding-left: 16px;
    max-height: 60px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* logo area */
.top-nav-logo {
    margin-right: 16px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.top-nav-logo img {
    display:block;
    max-height: 36px;;
    object-fit: contain;
}

.top-nav-header {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-right: 16px;
}

/* cta area */
.top-nav-cta {
    margin: 8px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

/* primary nav area */
.top-nav-primary-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
}
.top-nav-primary-menu {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.top-nav-mobile-menu {
    border-radius: 4px;
    background-color: white;
	position: absolute;
    top: calc(100% + 4px);
	list-style-type: none;
	margin: 0;
    padding: 12px;
	width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0px 3px 16px 0px rgba(41, 48, 54, 0.20);
}

.mobile-cta-link {
    flex: 1;
    padding: 8px 12px;
    display: block;
    text-decoration: none;
    color: #293036; /* var(--color-gray-800) */
}

.mobile-cta-link:active,
.mobile-cta-link.active {
    color: #004c9a; /* var(--color-blue-600) */
}

.mobile-cta-link:hover {
    color: #0065bd; /* color: var(--color-blue-500) */
}

.first-cta {
    border-top: 1px solid #ccc;
}

@media screen and (min-width: 1280px) {
    /* desktop adjustment */
    .top-nav {
        margin: 0 auto;
        padding-left: 0px;
    }
    .top-nav-content {
        max-width: 1280px;
        margin: 0 auto;
    }
  }
.header-item {
    padding-left: 14px;
    padding-right: 14px;
}

.header-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

/* duplicated from "global nav" for single connect register link in desktop header */
.primary-nav-link {
    display: block;
    text-decoration: none;
    color: #293036; /* var(--color-gray-800) */
}
.primary-menu-item-content {
    padding: 12px 14px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    cursor: pointer;
}
.primary-menu-item-content:visited,
.primary-menu-item-content:focus,
.primary-menu-item-content:active,
.primary-menu-item-content.active {
    background-color: #e0e4e7; /* var(--color-gray-100) */
    color: #293036; /* var(--color-gray-800) */
    text-decoration: none;
}
.primary-menu-item-content:hover {
    color: #293036; /* var(--color-gray-800) */
    text-decoration: none;
    background-color: #f7f8f9; /* var(--color-gray-50) */
}
/* :root {
	--min-tablet-width: 768px;
	--min-desktop-width: 1024px;
} */
/*
Unfortunately, CSS variables (custom properties) cannot be used directly in media query conditions due to how the browser processes the CSS.
The browser evaluates media queries during the parse time, while CSS variables are resolved during the runtime.
This means that the browser doesn't know the value of the CSS variable when it's evaluating the media query.

Without using a preprocessor like Sass or Less, you can't use variables directly in media queries.
TODO: update to use css variables when/if scss is integrated into tels-site
*/

.footer {
	max-width: 1280px;
	padding-left: 32px;
	padding-right: 32px;
	padding-top: 32px;
	padding-bottom: 32px;
	margin: 0 auto;
}

.footer a {
    font-weight: normal;
    text-decoration: none;
    word-break: break-word;
    word-wrap: break-word;
    font-family: "Inter var", sans-serif;
}

.footer a:hover {
    color: #004c9a;
    text-decoration: underline;
}

.footer-logo {
	margin-bottom: 24px;
}

.footer-logo img {
    max-height: 44px;
    object-fit: contain;
}

.footer-main {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.footer-section {
	flex: 1;
	padding-bottom: 24px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 12px;
}

.footer-section ul li:last-child {
    margin-bottom: 0;
}

.footer-section ul li a {
    color: #293036;
}

.footer-section-header {
    font-family: "Inter var", sans-serif;
    padding-bottom: 12px;
}

.footer-social {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.policy {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.policy-link {
	color: #0065bd;
}

.onetrust-image {
	position: relative;
	top: 2px;
}

@media only screen and (min-width: 768px) {
	.footer {
		padding-top: 48px;
		padding-bottom: 48px;
	}
	.footer-main {
		gap: 48px;
		grid-template-columns: repeat(2, 1fr);
	}
	.policy {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: 8px;
		row-gap: 8px;
	}
	.copyright {
		margin-bottom: 8px;
		margin-right: 8px;
	}

	.footer-info {
		margin-top: 48px;
	}
}

@media only screen and (min-width: 1024px) {
	.footer-main {
		grid-template-columns: repeat(4, 1fr);
	}

	.footer-main.col-3 .footer-contact {
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.footer-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
